import React, { useEffect } from "react";
import { ContainerCarousel } from "./home-container";
import { Header } from "./navbar";
import { CardContent } from "./card";
import { ServicesCard } from "./services-card";
import { CourseCard } from "./course-card";
import { FeedBackForm } from "./feedback-form";
import { FooterLinks } from "./footer";
import Testimonials from "./testimonial";



export const HomeGroupe = () => {
    return (
        <>
            <Header />
            <ContainerCarousel />
            <CardContent />
            <ServicesCard />
            <CourseCard />
            <br /><br />
            <Testimonials />
            <FeedBackForm />
            <FooterLinks />
        </>
    );
};
