import React, { useState } from "react";
import { Form, Row, Button } from "react-bootstrap";
import { CheckedFields, CheckedMessage, CheckedNumber } from "../Options/validate-contact-form";
import { submitFormContact } from "../Options/axios-request";

export const FeedBackForm = () => {
  const [fullname, setFullname] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [formErr, setFormErr] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (CheckedFields(fullname) && CheckedNumber(number) && CheckedMessage(message)) {
       await submitFormContact(fullname, email, number, message);
      setFormErr("");
    } else {
      setFormErr(
        "Le formulaire contient une  erreur verifiez si tous les champs sont remplis et que le numéro est correct "
      );
    }
  };

  return (
    <>
      <div className="services-card">
        <span> ................. </span>
        <h2 style={{ textAlign: "center" }}> Nous contactez ici </h2>
        <span> ................. </span>
      </div>
      <Form className="form" onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Label> Nom Complet </Form.Label>
            <Form.Control
              placeholder="Entrez votre prenom et nom"
              onChange={(e) => setFullname(e.target.value)}
            />
          </Form.Group>
        </Row>
        {formErr.length > 5 && (
          <Form.Label style={{ color: "red" }}>{formErr}</Form.Label>
        )}
        <Form.Group className="mb-3" controlId="formGridAddress1">
          <Form.Label>Numéro</Form.Label>
          <Form.Control
            placeholder="Entrez votre numéro"
            onChange={(e) => setNumber(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGridAddress2">
          <Form.Label>Adresse mail</Form.Label>
          <Form.Control
            placeholder="Entrez votre adresse mail"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGridAddress2">
          <Form.Label>Votre message</Form.Label>
          <br />
          <Form.Label style={{ color: message.length >= 20 ? "green" : "red" }}>
            {message.length > 0 && <p>Nombre de caractère {message.length} / min: 20</p>}
          </Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Minimum 20 charactères"
            rows={3}
            size="sm"
            onChange={(e) => setMessage(e.target.value)}
          />
        </Form.Group>
        <Button id="submit" type="submit">
          Envoyez
        </Button>
      </Form>
    </>
  );
};
