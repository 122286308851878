import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { NavDropdown } from 'react-bootstrap';
import { Stack, Navbar, Nav, Container } from "react-bootstrap";
// Menu de navigation
export const Header = () => {
  const [isLogin,setIsLogin] =  useState(false);

  const navigate = (link) => {
    if(link === ""){
      return alert("Service bientôt disponible revenez régulièrement pour vérifier.")
    }
    window.location.href = `https://${link}.informatiquesenwolof.com`;
  };
  const authNavigate = (link) => {
    window.location.href = `https://auth.informatiquesenwolof.com/${link}`;
  };

  useEffect(()=>{
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL_ISLOGIN}/api/login`,
      data: {
        email: "exemple@gmail.com",
        password: "user123456@",
      },
      withCredentials: true,
    })
      .then((res) => {       
        if (res.status === 201) setIsLogin(true);
      })
      .catch((err)=>console.log());
  })

  return (
    <>
      {/*Header */}
      <Stack direction="horizontal" gap={3} className="width">
        <div className="p-2" id="title">
          <Navbar.Brand>
            <h1 onClick={() => navigate("home")} style={{ cursor: "pointer" }}>
              Informatiques<span className="span"> en wolof</span>
            </h1>
          </Navbar.Brand>
        </div>
        <div className="p-2 ms-auto">
          <Button
            variant="primary"
            className="mail-btn"
            onClick={() =>
              window.location.href = "mailto:admin@sen-teranga.com"
            }>
            <img src="../images/icons/email.png" alt="secured-letter" />
          </Button>
        </div>
        <div className="p-2">
          <Button
            variant="primary"
            className="btn-wth"
            onClick={() => window.location.href = "https://wa.me/221704291214"
            }>
            <img src="../images/icons/whatsapp.png" alt="whatsapp" />
          </Button>
        </div>
      </Stack>
      {/*Menu de navigation */}
      <Navbar
        expand="lg"
        className="navbar full-width-navbar"
        variant="light"
        collapseOnSelect>
        <Container fluid>
          <Navbar.Brand className="link">Menu</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-collapse-id" />
          <Navbar.Collapse id="navbar-collapse-id">
            <Nav activeKey="/link" className="me-auto">
              <Nav.Link eventKey="link-event-key" className="link" onClick={() => navigate("home")}>
                Accueil
              </Nav.Link>
              <NavDropdown title="Formations" id="nav-dropdown" >
                <NavDropdown.Item  eventKey="event-key" onClick={() => navigate("free-course")}>Cours gratuits</NavDropdown.Item>
                <NavDropdown.Item  eventKey="event-key" onClick={() => navigate("course")}> Cours avancés </NavDropdown.Item>
                <NavDropdown.Item  eventKey="event-key" onClick={() => navigate("course")}>{isLogin ? "Mes formations" : ""}</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Services" id="nav-dropdown" >
                <NavDropdown.Item eventKey="event-key" onClick={() => navigate("")}>Outils pour restaurants</NavDropdown.Item>
                <NavDropdown.Item eventKey="event-key" onClick={() => navigate("")}> Marketplace  </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link eventKey="link-event-key" className="link" onClick={() => navigate("")}>
                Communauté
              </Nav.Link>
              <NavDropdown title="Mon compte" id="nav-dropdown" >
                <NavDropdown.Item  eventKey="event-key" onClick={() => navigate("my-account")} >{isLogin ? "Mon compte" : ""} </NavDropdown.Item>
                <NavDropdown.Item  eventKey="event-key" onClick={() => authNavigate("login")}> {isLogin ? "Déconnexion" : "Connexion"}  </NavDropdown.Item>
                <NavDropdown.Item  eventKey="event-key" onClick={() => authNavigate("")}> {isLogin ? "Mon compte" : ""}</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link eventKey="link-event-key" className="link" onClick={() => navigate("statics")}>
                Propos
              </Nav.Link>
              <Nav.Link eventKey="link-event-key" className="link" onClick={() => navigate("statics")}>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
