import React from "react";
import { BiRestaurant } from 'react-icons/bi';
import { HiAcademicCap, HiBookmark, HiUserGroup } from 'react-icons/hi';
import { Card, CardGroup } from "react-bootstrap";
import { HiBriefcase } from 'react-icons/hi';

export const CardContent = () => {
  const handleRedirect = (link) => {
    if(link === ""){
      return alert("Service bientôt disponible revenez régulièrement pour vérifier.")
    }
    window.location.href = `https://${link}.informatiquesenwolof.com`
  }
  return (
    <>
      <Card>
        <br />
        <div className="items">
          <div className="items-child" onClick={()=>handleRedirect("course")}>
            <HiAcademicCap />
            <p> Formations  </p>
          </div>
          <div className="items-child" onClick={()=>handleRedirect("")}>
            <BiRestaurant />
            <p> Restaurants  </p>
          </div>
          <div className="items-child" onClick={()=>handleRedirect("")}>
            <HiUserGroup />
            <p> Communauté  </p>
          </div>
          <div className="items-child" onClick={()=>handleRedirect("")}>
            <HiBookmark />
            <p> Nos Services  </p>
          </div>
          <div className="items-child" onClick={()=>handleRedirect("")}>
            <HiBriefcase />
            <p> Markeplace  </p>
          </div>
        </div>
        <div className="card">
          <Card.Header className="card-heder">
            Nous sommes à l'avant-garde de l'innovation numérique auSénégal.💻
          </Card.Header>
          <Card.Body>
            <blockquote className="blockquote mb-0">
              <p> Nos services: </p>
              <footer className="blockquote-footer">
                <br />
              </footer>
            </blockquote>
          </Card.Body>
        </div>
      </Card>
      <br />
      <CardGroup className="card-groupe">
        <Card className="card-native">
          <img src="../images/settings.png" />
          <Card.Body>
            <Card.Title className="card-title">
              Ce que nous vous offrons :{" "}
            </Card.Title>
            <Card.Text>
              Une communauté dynamique : Rejoignez des milliers de
              professionnels, étudiants, et entrepreneurs partageant les mêmes
              ambitions. Des outils numériques puissants pour booster votre
              activité. Une expérience centréesur vos besoins, conçue pour les
              contextes locaux.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="card-native">
          <img src="../images/acheivement.png" />
          <Card.Body>
            <Card.Title className="card-title">Notre Mission</Card.Title>
            <Card.Text>
              Accompagner la communauté africaine dans son développement
              professionnel et entrepreneurial en mettant à disposition des
              outils, des formations, et un réseau adapté à ses réalités.{" "}
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="card-native">
          <img src="../images/chart.png" />
          <Card.Body>
            <Card.Title className="card-title">
              Des formations accessibles
            </Card.Title>
            <Card.Text>
              Accédez à des contenus de formation en wolof, en français, et
              bientôt dans d’autres langues africaines. Notre objectif : rendre
              la formation numérique accessible à tous, sans barrière
              linguistique.{" "}
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>
    </>
  );
};