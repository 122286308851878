import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";

export const FooterLinks = () => {
  return (
    <MDBFooter bgColor="light" className="text-center text-lg-start text-muted">
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
        </div>

        <div>
          <a href="" className="me-4 text-reset">
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href="" className="me-4 text-reset">
            <MDBIcon fab icon="twitter" />
          </a>
          <a href="" className="me-4 text-reset">
            <MDBIcon fab icon="google" />
          </a>
          <a href="" className="me-4 text-reset">
            <MDBIcon fab icon="instagram" />
          </a>
          <a href="" className="me-4 text-reset">
            <MDBIcon fab icon="linkedin" />
          </a>
          <a href="" className="me-4 text-reset">
            <MDBIcon fab icon="github" />
          </a>
        </div>
      </section>

      <section className="">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                <MDBIcon icon="gem" className="me-3" />
                Informatiques en wolof
              </h6>
              <p>
                Nous sommes à l'avant-garde de l'innovation numérique au
                Sénégal.💻
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Services</h6>
              <p>
                <a href="#!" className="text-reset">
                  Formations en ligne
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Outils pour tous
                </a>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                Nos réseaux-sociaux
              </h6>
              <div className="social-media-link">
                <p>
                  <img
                    width="20"
                    height="30"
                    src="../images/icons/facebook.png"
                    alt="facebook-new"
                  />
                </p>
                <p>
                  <img
                    width="30"
                    height="30"
                    src="../images/icons/instagram.png"
                    alt="instagram-new"
                  />
                </p>
                <p>
                  <img
                    width="30"
                    height="30"
                    src="../images/icons/youtube.png"
                    alt="youtube-play"
                  />
                </p>
                <p>
                  <img
                    width="30"
                    height="30"
                    src="../images/icons/tiktok.png"
                    alt="tiktok"
                  />
                </p>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        © 2024 Copyright:
        <a className="text-reset fw-bold" href="https://mdbootstrap.com/">
          informatiquesenwolof.com
        </a>
      </div>
    </MDBFooter>
  );
};
