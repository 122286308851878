import React from "react";
import { Button, Col, Row, Carousel, Container, Form } from "react-bootstrap";
import { FaSearch } from 'react-icons/fa';
import { BiRestaurant } from 'react-icons/bi';
const h2 = `Un réseau pour tous les professionnels`
const text2 = ` Connectez-vous avec des talents locaux et internationaux,
                        développez votre réseau et trouvez des opportunités
                        professionnelles adaptées à votre secteur.`
export const ContainerCarousel = ({ otitle = h2, otext = text2 }) => {
  return (
    <>
      <div>
        <div className="carousel-2">
          <div className="carousel-child-2">
            <h1> {otitle} </h1>
            <p style={{color:"white"}}>Des outils pour tous !</p>
            <div className="container" justify-content-center>
              <div className="row">
                <div className="col">
                  <Form.Control className="search" type="search" placeholder="Rechercher le service que vous voulez ici" />
                  <Button className="s-btn">Rechercher <FaSearch /> </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
